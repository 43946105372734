import client from '@/api/AxiosInstance.js'
export default {
  makeAppointment(params) {
    return client.post('/appointment/submit', {
      date: params.date,
      time: params.time,
      channel: params.channel,
      url: params.url,
    })
  },

  appointmentDate(params) {
    return client.post('/appointment/available-time-slots', {
      date: params.date,
    })
  },

  resubmitAppointment(params) {
    return client.post('/appointment/update/' + params.id, {
      date: params.date,
      time: params.time,
      channel: params.channel,
      url: params.url,
    })
  },
}
