<template>
  <div class="container d-flex align-items-start flex-column" fluid="lg">
    <div class="row">
      <div class="col-12 bg-primary-light">
        <h6 class="text-white text-center py-4" v-html="$t('synpitarn_checking')"></h6>
        <h6 class="text-white text-center py-4">{{ $t('choose_desire_time') }}</h6>
      </div>

      <div class="col-12 pt-4">
        <div class="d-flex justify-content-between">
          <h6>
            {{ $t('appointment_date.label') }}
            <span v-if="!$v.appointmentDate.required" class="text-danger">*</span>
          </h6>
          <get-help modal_id="modal-ad" title="What do we mean by 'name'" :content="$t('appointment_date.help')" />
        </div>

        <VueDatePicker
          ref="menu"
          v-model="date"
          :format="format"
          :min-date="minDate"
          :max-date="maxDate"
          fullscreen-mobile
          color="#2E3192"
          @onOpen="menu = true"
          @onClose="menu = false"
        >
          <template #activator>
            <div class="position-relative w-100">
              <b-form-input
                v-model.trim="$v.appointmentDate.$model"
                v-mask="'####-##-##'"
                size="sm"
                :class="{ error: $v.appointmentDate.$error }"
                :placeholder="$t('appointment_date.placeholder')"
                disabled
              ></b-form-input>

              <i class="append-icon ri-calendar-2-line ri-lg"></i>
            </div>
          </template>
        </VueDatePicker>
        <!-- <div class="small text-light">{{ $t("appointment_date.example") }}</div> -->
      </div>

      <div class="small text-center text-danger p-4">{{ $t('appointment_date_description') }}</div>

      <div v-if="appointmentTimeOptions" class="col-12 pt-4">
        <div class="d-flex justify-content-between">
          <h6>
            {{ $t('appointment_time.label') }}
            <span v-if="!$v.appointmentTime.required" class="text-danger">*</span>
          </h6>
        </div>
        <multiselect
          v-model.trim="$v.appointmentTime.$model"
          :class="{ error: $v.appointmentTime.$error }"
          deselect-label
          :placeholder="$t('appointment_time.placeholder')"
          :options="appointmentTimeOptions"
          :searchable="false"
          :allow-empty="false"
          :show-labels="false"
        ></multiselect>
        <!-- <div class="small text-light">{{ $t("appointment_time.example") }}</div> -->
      </div>

      <div class="col-12 pt-4">
        <div class="d-flex justify-content-between">
          <h6>
            {{ $t('social_channel.label') }}
            <span v-if="!$v.socialChannel.required" class="text-danger">*</span>
          </h6>
        </div>
        <multiselect
          v-model.trim="$v.socialChannel.$model"
          :class="{ error: $v.socialChannel.$error }"
          deselect-label
          track-by="name"
          label="name"
          :placeholder="$t('social_channel.placeholder')"
          :options="socialChannelOptions"
          :searchable="false"
          :allow-empty="false"
          :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
        </multiselect>
      </div>

      <div class="col-12 pt-4">
        <div class="d-flex justify-content-between">
          <h6>
            {{ $t('how_can_contact.label') }}
            <span v-if="!$v.socialUrl.required" class="text-danger">*</span>
          </h6>
        </div>
        <b-form-input
          v-model.trim="$v.socialUrl.$model"
          type="text"
          size="sm"
          :class="{ error: $v.socialUrl.$error }"
          :placeholder="$t('how_can_contact.placeholder')"
        ></b-form-input>
      </div>
    </div>

    <div class="row mt-auto mx-auto pt-4">
      <div class="col-12 py-4 text-center">
        <b-button pill variant="primary px-4" @click="makeAppointment()">
          {{ $t('make_appointment') }}
        </b-button>
      </div>
    </div>

    <loading :is-busy="isBusy"></loading>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import '@/assets/scss/vue-datepicker.min.css'
import '@/assets/scss/vue-multiselect.min.scss'
import { required } from 'vuelidate/lib/validators'
import GetHelp from '@/components/GetHelp.vue'
import Loading from '@/components/Loading.vue'
import dateFormat from '@/mixins/dateFormat.js'
import AppointmentService from '@/api/service/AppointmentService'
import LoanService from '@/api/service/LoanService'
const date = new Date()
export default {
  components: {
    Multiselect,
    GetHelp,
    Loading,
  },
  mixins: [dateFormat],
  data() {
    return {
      menu: false,
      date,
      minDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1}`,
      maxDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 14}`,
      format: 'MM-DD-YYYY',
      appointmentDate: null,
      selectedDate: null,
      appointmentTime: null,
      appointmentTimeOptions: null,
      socialUrl: null,
      socialChannel: { type: 'messenger', name: 'FB Messenger' },
      socialChannelOptions: [
        { type: 'messenger', name: 'FB Messenger' },
        { type: 'viber', name: 'Viber' },
        { type: 'line', name: 'Line' },
      ],
      isBusy: false,
      loanApplicationId: null,
    }
  },
  watch: {
    date: function (val) {
      this.appointmentDate = this.dateFormatter(val, 'DD-MM-YYYY')
      this.selectedDate = val
      if (this.date) {
        this.getAppointmentTime()
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.menu.$refs.agenda.mode = 'year'))
    },
  },
  created() {
    this.getLoanApplication()
  },
  methods: {
    showDatePicker() {
      this.$bvModal.show('modal-date-picker')
    },
    async getLoanApplication() {
      try {
        this.isBusy = true
        const res = await LoanService.loanApplication()
        this.isBusy = false
        console.log(res)
        if (res.status == 200) {
          const loanResponse = res.data.data
          if (loanResponse) {
            if (loanResponse.appointment_resubmit) {
              this.loanApplicationId = loanResponse.id
            } else {
              this.$router.push({ name: 'home' })
            }
          } else {
            this.$router.push({ name: 'home' })
          }
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async makeAppointment() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.isBusy = true
        try {
          const res = await AppointmentService.resubmitAppointment({
            id: this.loanApplicationId,
            date: this.dateFormatter(this.selectedDate, 'YYYY-MM-DD'),
            time: this.appointmentTime ? this.appointmentTime : null,
            channel: this.socialChannel ? this.socialChannel.type : null,
            url: this.socialUrl,
          })

          this.isBusy = false

          if (res.status == 200) {
            this.$store.dispatch('auth/setLoanSubmitted', {
              loanSubmitted: true,
            })
            this.$router.push({ path: 'appointment-success' })
          }
        } catch (err) {
          this.isBusy = false
          this.showAlert(err)
        }
      }
    },
    async getAppointmentTime() {
      this.isBusy = true
      this.appointmentTimeOptions = null
      this.appointmentTime = null
      try {
        const res = await AppointmentService.appointmentDate({
          date: this.dateFormatter(this.selectedDate, 'YYYY-MM-DD'),
        })

        this.isBusy = false

        if (res.status == 200) {
          const timeResponse = res.data.data
          if (timeResponse.length <= 0) {
            this.showAlert(this.$t('no_time_to_appointment'))
          } else {
            this.appointmentTimeOptions = timeResponse
          }
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
  },
  validations: {
    appointmentDate: {
      required,
    },
    appointmentTime: {
      required,
    },
    socialUrl: {
      required,
    },
    socialChannel: {
      required,
    },
  },
}
</script>

<style></style>
